<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <div class="prt-card-header">
                <div class="header-naming">
                  <CIcon name="cil-sitemap"/>
                  Landingpages
                </div>
                <div class="header-button">
                  <CButton
                      to="landingpage/new"
                      name="backagain"
                      size="sm"
                      key="key"
                      color="dark">
                    <CIcon size="sm" name="cil-plus"/>
                    <span> Neue Landingpage erstellen</span>
                  </CButton>
                </div>
              </div>
            </slot>
          </CCardHeader>
          <CCardBody v-if="!loading">
            <CDataTable
                :items="tableData"
                :itemsPerPageSelect="{ label: 'Landingpages pro Seite' }"
                :fields="getTableFields()"
                :noItemsView="{ noResults: 'Keine Suchergebnisse verfügbar',
                    noItems: 'Keine Landingpages verfügbar' }"
                hover
                striped
                bordered
                fixed
                column-filter
                table-filter
                :tableFilter="{ label: 'Landingpage suchen', placeholder: 'Suchbegriff eingeben' }"
                items-per-page-select
                sorter
                :sorterValue="{ column: 'sendAt', asc: true }"
                pagination
                :loading="tableShowLoading"
            >
              <template #active="{item}">
                <td>
                  <CBadge :color="getTableBadge(item.active)">
                    {{item.active ? 'Aktiv' : 'Deaktiv'}}
                  </CBadge>
                </td>
              </template>
              <template #show_details="{item, index}">
                <td class="py-2">
                  <CButton
                      :to="'landingpage/edit/' + item.id"
                      color="primary"
                      variant="outline"
                      square
                      size="sm"
                  >
                    Bearbeiten
                  </CButton>
                </td>
              </template>
              <template #sendAt="{ item }">
                <td>
                  {{ $moment(item.sendAt).format($commons.DATE_TIME_FORMAT) }}
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Hilfe</strong>
            </slot>
          </CCardHeader>
          <CCardBody>
            <p>
              Dank der Marketing-Cloud benötigen Sie wenige Minuten, um ansprechende Landingpages zu entwerfen, mit denen Sie Ihre neue Leads gewinnen werden. 
              <br />
              Mit unserem Baukasten können Sie Landingpages genau so gestalten, wie Sie es möchten. 
              Sie können bei Bedarf aus über 150 Domains wählen und Ihre persönliche Subdomain erstellen.
              <br />
              Wenn Sie Interesse an der exklusiven Nutzung einer Domain haben, schreiben Sie uns gerne.
              
            </p>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import DoubleBounceLoader from '@/components/loaders/DoubleBounceLoader'
import commons from '@/commons'
import axios from 'axios'
export default {
  name: 'LandingPages',
  components: {
    DoubleBounceLoader
  },
  data () {
    return {
      loading: false,
      saving: false,
      tableData: [],
      tableShowLoading: true
    }
  },
  mounted () {
    this.loading = true
    this.loadLandingPages()
  },
  methods: {
    loadLandingPages() {
      axios.get('/landingPage/page')
          .then(response => {
            let content = []
            response.data.forEach(value => {
              content.push(commons.flatObject(value))
            })
            this.tableData = content
            this.loading = false
          })
          .catch(err => console.error('Problem while fetching landing pages', err))
          .finally(() => this.tableShowLoading = false)
    },
    getTableFields() {
      return [
        {key: 'name', label: 'Name'},
        {key: 'domain', label: 'Domain'},
        {key: 'active', label: 'Aktiv?'},
        {
          key: 'show_details',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        }
      ]
    },
    getTableBadge(type) {
      return type ? 'success' : 'danger'
    }
  }
}
</script>
